@font-face {
  font-family: 'Sofia-Pro-Regular';
  src: url('../fonts/Sofia Pro Regular.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Sofia-Pro-semibold';
  src: url('../fonts/Sofia Pro Semi.otf'); /* IE9 Compat Modes */
}
@font-face {
  font-family: 'Lust-Slim';
  src: url('../fonts/Lust Slim.otf'),
url('../fonts/Lust Slim.ttf'); /* IE9 Compat Modes */
}

body{
   font-family: 'Sofia-Pro-Regular';
}

h1,h2,h3,h4,h5,h6{
     font-family: 'Lust-Slim'; 
}
