.footer{
    background-color: #25262D;
        color: #fff;
        position: relative;

        padding-top: 3rem;
        padding-bottom: 0;

        h4{
           
font-family: Sofia-Pro-Regular;
font-size: 18px;
color: #FFFFFF;
letter-spacing: 1.5px;
text-transform: uppercase;
margin-bottom: 30px;
        }
        p{
           font-size: 14px; 
        }

        span{
            color: #A1A8AE;
            font-size: 12px; 
            display: block;
        }

        .search{
                width: 470px;
                 position: relative;
    float: right;
                  .search-box{
                         
    border-radius: 100px;
    border: 0;
    padding: 24px;
            
        }
        .btn{
            font-family: Sofia-Pro-SemiBold;
               position: absolute;
    top: 0;
    right: 0;
    border-radius: 100px;
    padding: 11px;
    width: 120px;

        }
        }

    svg{
              position: absolute;
    top: -117px;
    width: 100%;
    left: 0;


    }
      
}

.social{
    background-color: #1F2026;
    padding: 15px;
    margin-top: 1rem;
    padding-top: 1.5rem;

    .logo{
            width: 98px;
    opacity: .7;
    }
    ul{
            flex-direction: row;

            li{
                margin-right: 15px;
            }
    }

    .copyrights{
        opacity: .7;
        font-size: 14px;
    }
}

 @media(max-width:1199px){
     .footer svg {
    position: absolute;
    top: -60px;
    width: 100%;
    left: 0;
}


 @media(max-width:576px){


.footer svg {
    position: absolute;
    top: -20px;
    width: 100%;
    left: 0;
}

.footer,.text-right{
    text-align: center !important;
}

.footer .search {
    width: 100%;
    position: relative;
    float: right;
}

.social {
text-align: center;
display: none;
}
.footer{
    padding-bottom: 2rem;
}

 }



 }