.category-selector{
        z-index: 4;
    position: relative;
    top: -97px;

    background-image: url(./../img/circle-left.svg),url(./../img/circle-right.svg);
    background-repeat: no-repeat;
    background-position: center left,bottom right;
    background-size: 110px;

    .category-options{

        h5{

font-size: 24px;
color: #FFFFFE;
letter-spacing: 0;
position: relative;
margin-bottom: 10px;
text-align: left;
text-shadow: 0 5px 4px rgba(0,0,0,0.50);
&:after{
   content: '';
    height: 1px;
    width: 85%;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 0px;
    opacity: .6;
}
        }
.category-container{
    overflow: hidden;
    cursor: pointer;

    img{
        transition: transform .3s ease-in-out;
    }

    &:hover{
        img{
            transform: scale(1.1);
        }
    }

position: relative;

    &:after{
        content:"";
background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, #041F42 100%);
position: absolute;
bottom: 0;
width: 100%;
left: 0;

    height: 70%;
        
    }
}
}

.category-heading{
        position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 2;

    h3{

font-size: 32px;
color: #FFFFFF;
letter-spacing: 0;
text-shadow: 0 2px 11px rgba(0,0,0,0.78);
    }
}


}

.about{
      z-index: 4;
    position: relative;
    top: -57px;
        background-image: url(./../img/about.jpg);
        background-size: cover;


        .about-item{
            background-color: #fff;
                padding: 80px 60px;

                h3{
font-family: Sofia-Pro-SemiBold;
font-size: 24px;
color: #363D4F;
letter-spacing: 0;
line-height: 24px;
                }

                &.boder-right {
    border-right: 1px solid #e2e1e1;
        }

         &.boder-bottom {
    border-right: 1px solid #e2e1e1;
        }
}

.row{
    background-color: #fff;
}

.holder{
    background-color: #fff;
    box-shadow: 10px 38px 61px 3px rgba(85, 85, 85, 0.12156862745098039);

    a{
        text-transform: uppercase;
        text-decoration: none;
        font-size: 14px;

        img{
            margin-left: 5px;
        }
    }
}

.line{
   
background: #F8E71C;
border-radius: 100px;
width: 70px;
height: 5px;
margin: auto;
margin-bottom: 5px;
}

svg{
    position: absolute;
}

.bottom{
    position: absolute;
    bottom:-2px;
}
}

.spacer{
      min-height: 218px;
    position: relative;
    background-image: url(./../img/circle.svg),url(./../img/circle-left.svg), url(./../img/circle-right.svg);
    background-repeat: no-repeat;
    background-position: bottom -110px center,bottom left,bottom right;
    background-size: 363px,75px,75px;
    top: -62px;

}


 @media(max-width:576px){
    .spacer {
    min-height: 88px;
    position: relative;
    background-size: 183px,35px,35px !important;
    top: -2px;
}

.category-selector .category-options h5:after{
    width: 55%;
}

.about .holder a img {
    margin-left: 5px;
    margin-bottom: 4px;
}
 }