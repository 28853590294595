.header{
    /*background-image: url('../img/video-bg.jpg');*/
    min-height: 595px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
         background-position: top -70px center;
         padding-top: 40px;

        &:before{
                    
        background-image: -webkit-linear-gradient(bottom, rgba(4,101,162,0.00) 31%, #0E3D59 92%);
        background-image: -o-linear-gradient(bottom, rgba(4,101,162,0.00) 31%, #0E3D59 92%);
        background-image: linear-gradient(to top, rgba(4,101,162,0.00) 31%, #0E3D59 92%);
            content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;

       
                }

                svg{
                        position: absolute;
    bottom: -10px;
                }


                .navbar-nav{
                    li.nav-item{
                        a.nav-link{
                            color:#fff;
                            font-size: 14px;
                            padding-top: 15px;
                            transition: opacity .3s ease-in-out;

                            &:hover{
                                opacity: .7;
                            }

                        }

                        &.active{
                            a{
                                 color:$brand-primary;
                            }
                        }
                    }
                }


                .dropdown-right{
                    .user{
                        a{
                            display: flex;
                            align-items: center;

                            img{
                                    margin-bottom: 5px;
    margin-right: 7px;
                            }
                        }
                    }

                    .cart{
                        a{
                                padding-top: 10px !important;
                        }
                    }
                }


                .header-text{
                        padding-top: 10rem;
    text-align: center;

        color:#fff;

        h1{
          
font-size: 44px;
color: #FFFFFF;
letter-spacing: -0.82px;
text-align: center;
line-height: 48px;
text-shadow: 0 2px 12px rgba(0,0,0,0.50);
        }

                }

               




                      


}


        .form-group.search{
            text-align: center;
            width: 550px;
            margin: auto;
            position: relative;
            .form-control{
                    background-color: #fff;
    padding: 30px;
    border-radius: 100px;
    border: 0;
    
box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
position: relative;

&::placeholder {
  color: #9B9B9B;
}




            }

                    .btn{
border-radius: 100px;
    position: absolute;
    top: 0px;
    padding: 17px 30px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 156px;
    font-weight: bold;
        }

      
        }

          .navbar-toggler{
            border:0;
        }


        @media(max-width:1400px){
    .header{
        background-position: top center;
    }
}

 @media(max-width:992px){
.navbar-collapse {
    position: absolute;
    background-color: rgba(9, 49, 73, 0.85);
    width: 100%;
    top: 64px;
    z-index: 5;
    padding: 30px;
    border-radius: 15px;
    text-align: right;
    right: 0;
}

.form-group.search {
    text-align: center;
    width: 420px;
    
    }

 }

 @media(max-width:576px){

     #block{
     display: none;
 }

     .header{
    background-image: url('../img/video-bg.jpg');
    }
.form-group.search {
    text-align: center;
        width: 90%;

        .btn {
   
    position: relative;
   
    width: 100%;
    
    margin-top: 6px;
}
    
    }

    .header .header-text {
    padding-top: 4rem;
    text-align: center;
    color: #fff;
}

.header{
    min-height: 695px;

}

.holder{
    text-align: center;

    img{
        margin-bottom: 1.5rem;
    }
}




 }

 #block{
     position: absolute !important;
 }